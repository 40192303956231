import { navigate } from "gatsby-link";
import {
  Box,
  Image,
  ResponsiveContext,
  TextInput,
  Button,
  Text,
} from "grommet";
import React, { useState } from "react";
import Layout from "../components/elements/layout";
import mainLogo from "../images/logo.png";
import service from "../services/login_service";
import storageService from "../services/storage_service";

const LoginPage = ({ size, logo }) => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const login = () => {
    if (
      !username ||
      !username.includes("@") ||
      !username.includes(".") ||
      username.length < 6
    ) {
      setError("Identifiant non valid");
      return;
    }

    if (!password || password.length < 6) {
      setError("Mot de passe au moin 6 caractères");
      return;
    }
    setError("");
    setLoading(true);
    service
      .login(username, password)
      .then((res) => {
        setLoading(false);
        storageService.saveAuth(res.data);
        setTimeout(() => {
          navigate("/home");
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
        setError("Mauvais email/mot de pass");
      });
  };

  const inputStyle = {
    background: "#EDEDED",
    fontSize: 14,
    fontWeight: 400,
    borderRadius: 10,
    border: "none",
  };
  return (
    <Layout style={{ justifyContent: "center", alignContent: "center" }}>
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box
            justify="center"
            as="main"
            flex
            overflow="auto"
            width="large"
            direction="column"
            alignContent="center"
            alignSelf="center"
          >
            <Box
              width="medium"
              alignSelf="center"
              justify="center"
              alignContent="center"
              gap="medium"
            >
              <Image src={logo || mainLogo} width={250} margin="auto" />

              <Text
                size="small"
                style={{ color: "#dc3545" }}
                textAlign="center"
              >
                {error}
              </Text>
              <Box width="large" gap="small">
                <TextInput
                  placeholder="Email "
                  onChange={(event) => setUserName(event.target.value)}
                  style={inputStyle}
                />
                <TextInput
                  placeholder="Mot de passe "
                  type="password"
                  onChange={(event) => setPassword(event.target.value)}
                  style={inputStyle}
                />
                <Box width="small" alignSelf="center">
                  <Button
                    primary
                    label="Connexion"
                    onClick={login}
                    disabled={loading}
                  />
                </Box>
                <Box width="small" alignSelf="center">
                  <Button
                    onClick={() => {
                      navigate("/forgot_password");
                    }}
                  >
                    <Text
                      style={{ color: "#707070", fontSize: 14 }}
                      textAlign="center"
                    >
                      J'ai oublié mon mot de passe
                    </Text>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Layout>
  );
};

export default LoginPage;
